import Vue from 'vue'
import VueRouter from 'vue-router'

// import index from "../views/index.vue"
import upload from "../views/upload.vue"
import entries from "../views/entries.vue"
import mine from "../views/mine.vue"
import login from "../views/login.vue"
import setting from "../views/setting.vue"
import detail from "../views/detail.vue"
import baoming from "../views/baoming.vue"
import sign from "../views/baoming2"


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'index',
		component: login
	},
	{
		path: '/upload',
		name: 'upload',
		component: upload
	},
	{
		path: '/entries',
		name: 'entries',
		component: entries
	},
	{
		path: '/mine',
		name: 'mine',
		component: mine
	},
	// {
	// 	path: '/login',
	// 	name: 'login',
	// 	component: login
	// },
	{
		path: '/setting',
		name: 'setting',
		component: setting
	},
	{
		path: '/detail',
		name: 'detail',
		component: detail
	},
	{
		path: '/sign',
		name: 'baoming',
		component: baoming,
		meta:{
			H5: true
		}
	},
	{
		path: '/baoming',
		name: 'sign_up',
		component: sign,
		meta:{
			H5: true
		}
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
