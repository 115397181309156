<template>
  <div class="wrapper-page">
    <div class="header">
      <div class="title">
        第八届(2021)福建文创奖·红色文创大赛
      </div>
      <div class="nav-list">
        <div
          class="nav"
          v-for="(item, index) in navList"
          :key="index"
          :class="{ active: type == item.type }"
          @click="navTo(item.url)"
        >
          <div class="text">{{ item.text }}</div>
        </div>
        <div class="nav">
          <el-dropdown>
            <div class="el-dropdown-link">
              <img
                v-if="jwt"
                class="avatar"
                src="../assets/img/avatar.png"
              />
              <img v-else class="avatar" src="../assets/img/headicon.png" @click="login"/>
            </div>
            <el-dropdown-menu slot="dropdown" v-if="jwt">
              <el-dropdown-item @click.native="checkLogin('/mine')"
                >我的作品</el-dropdown-item
              >
              <el-dropdown-item @click.native="checkLogin('/setting')"
                >设置</el-dropdown-item
              >
              <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="container" id="container">
      <div class="upload-box" v-if="upload">
        <div class="upload" @click="checkLogin('/upload')">上传作品</div>
      </div>

      <slot></slot>
    </div>

    <el-dialog :visible="dialogVisible" width="30%" center :show-close="false">
      <div class="dialog-text">登录后才能上传作品哦</div>
      <div slot="footer" class="dialog-btn" @click="login">去登录</div>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
export default {
  props: {
    type: {
      type: String,
      default: "index",
    },
    upload: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      jwt: store.getters.jwt,
      navList: [
        { text: "我的作品", url: "/mine", type: "index" },
      ],
      dialogVisible: false,
    };
  },
  mounted() {},
  methods: {
    navTo(url) {
      this.$router.push(url);
    },
    checkLogin(url) {
      if (!this.jwt) {
        store.commit("routerUrl", url);
        this.dialogVisible = true;
      } else {
        this.$router.push(url);
      }
    },
    logout() {
      store.commit("logout");
      this.$router.replace("/");
    },
    login() {
      this.dialogVisible = false;
      this.$router.push("/");
    },
  },
};
</script>

<style lang="less" scoped>
@import "../assets/style/style.less";

.wrapper-page {
  width: 100%;
  //min-width: 1152px;

  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    //min-width: 1152px;
    height: 4rem;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    text-align: center;
    z-index: 10;

    .title {
      padding: 1rem;
      font-weight: bold;
      font-size: 1.3rem;
      color: @mainColor;
    }

    .nav-list {
      display: flex;
      justify-content: center;
      align-items: center;

      .nav {
        display: flex;
        justify-content: center;
        align-items: center;

        .text {
          color: #000000;
          padding: 0.1rem;
          margin: 0 2rem;
        }
        .avatar {
          display: block;
          width: 2rem;
          height: 2rem;
          margin: 0 1.5rem;
        }
      }

      .active {
        .text {
          font-weight: bold;
          color: @mainColor;
          border-bottom: 0.15rem solid @mainColor;
        }
      }
      .nav:hover {
        cursor: pointer;
      }
    }
  }

  .container {
    width: 100%;
    padding-top: 4rem;
    //min-width: 1152px;
    .upload-box {
      width: 100%;
      height: 14rem;
      //background: @mainColor url(../assets/img/top_bg.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .upload {
        width: 21.5rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: @mainColor;
        font-size: 1.3rem;
        background: #ffffff;
      }
    }
  }
}
</style>
