import { post,get} from "@/api/index"

export function praise(params) {
  return get('/Wx/index/praise', params)
}

export function worksInfo(params) {
  return get( '/Wx/index/worksInfo',params)
}

export function searchWorks(params) {
  params['is_web'] = 1
  return get('/Wx/index/works', params)
}

export function smsCode(params) {
  return get('/Wx/user/smsCode', params)
}

export function cityList(params) {
  return get('/Wx/index/city', params)
}

export function informationList(params) {
  return get('/Wx/index/information', params)
}

export function login(params) {
  return post('/Wx/user/login', params)
}

export function saveInfo(params) {
  return post('/Wx/user/saveInfo', params)
}

export function updateMobile(params) {
  return post('/wx/user/updateMobile', params)
}

export function saveEntries(params) {
  return post('/Sapi/entries/saveEntries', params)

}
export function deleteEntries(params) {
  return post('/sapi/entries/deleteEntries', params)
}

export function entriesList(params) {
  return get('/sapi/entries/myEntriesList', params)
}

export function signUp(params) {
  return post('/wx/index/signUp', params)
}
