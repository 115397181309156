<template>
  <wrapper-page :upload="false" type="mine">
    <div class="upload">
      <div class="title-box">
        <div class="title">我的作品</div>
      </div>

      <div class="box">
        <div class="cell">
          <div class="title">作品名称</div>
          <el-input
            class="input"
            v-model="name"
            maxlength="20"
            placeholder="请输入作品名称"
          ></el-input>
        </div>

        <div class="cell">
          <div class="title">作品分类</div>
          <el-radio
            class="radio"
            v-model="type"
            :label="item.type"
            v-for="(item, index) in typeList"
            :key="index"
            >{{ item.name }}</el-radio
          >
        </div>

        <div class="cell">
          <div class="title">作品介绍</div>
          <el-input
            class="textarea"
            type="textarea"
            maxlength="300"
            show-word-limit
            placeholder="请输入作品介绍，最多300字"
            v-model="info"
          >
          </el-input>
        </div>
      </div>

      <div class="box">
        <div class="video">
          <div class="btn" @click="uploadVideo()">上传作品</div>
          <div class="name">{{ videoName }}</div>
          <div class="progress">
            {{ progress == 100 ? "上传完成" : "上传中" }}
            <el-progress :percentage="progress"></el-progress>
            <!-- <img class="close" src="../assets/img/progress_close.png" alt="" /> -->
          </div>
        </div>
        <div class="tip">
          1、支持mp4视频格式<br />
          2、视频分辨率不小于1280*720像素<br />
          3、视频时长不超过10分钟，不超过500M<br />
          4、若无视频则无需上传此项
        </div>
      </div>

      <div class="box">
        <div class="img-box">
          <div class="image"   v-for="(item, index) in imgList"  :key="index">
            <el-image
              class="img"
              :src="item"
              @click="uploadImg(index)"
            ></el-image>
            <img class="del" src="../assets/img/delete.png"   @click="delImg(index)"/>
          </div>

          <div class="add-box">
            <div class="add" @click="uploadImg(-1)">
              <img src="../assets/img/img_add.png" alt="" />
              <div class="text">上传作品</div>
            </div>
            <div class="add-tip">
              上传说明：<br />
              1、作品设计精度不低于300pdi、A3尺寸<br />
              2、图片版面大小为A3(297mm x 420mm)<br />
              3、每张图纸提供小于10M的jpg/png格式文件<br />
              4、建议上传3-5张白底图
            </div>
          </div>
        </div>
      </div>

      <div class="box">
        <div class="img-box">
          <div class="image" v-if="coverImg">
            <el-image
              class="img"
              :src="coverImg"
              @click.native="uploadCover()"
            ></el-image>

            <img class="del" src="../assets/img/delete.png"  @click="delCover()"/>
          </div>

          <div v-else class="add-box" @click="uploadCover()">
            <div class="add">
              <img src="../assets/img/img_add.png" alt="" />
              <div class="text">上传封面</div>
            </div>
            <div class="add-tip">
              上传说明：<br />
              1、每张图纸提供小于10M的jpg格式文件<br />
              2、最多只上传1张
            </div>
          </div>
        </div>
      </div>

      <div class="btn-box">
        <div class="btn1" @click="submit">发布作品</div>
        <div class="btn2" @click="submit('preview')">预览</div>
      </div>
    </div>
    <upload
      ref="upload"
      @change="uploadSuccess"
      @progress="progressChange"
    ></upload>

    <el-dialog :visible="dialogVisible" width="30%" center :show-close="false">
      <div class="dialog-text">作品已发布，请等待审核</div>
      <div slot="footer" class="dialog-btn" @click="back">返回</div>
    </el-dialog>
    <el-dialog :visible="mineVisible" width="30%" center :show-close="false">
      <div class="dialog-text">请先完善个人资料</div>
      <div slot="footer" class="dialog-btn" @click="mine">去完善</div>
    </el-dialog>
  </wrapper-page>
</template>

<script>
import Upload from "../components/upload.vue";
import wrapperPage from "../components/wrapperPage.vue";

import { saveEntries, worksInfo } from "../api/api";

import store from "@/store";

export default {
  components: { wrapperPage, Upload },
  data() {
    return {
      dialogVisible: false,
      mineVisible: false,
      typeList: [
        { name: "红色纹样", type: "1" },
        { name: "红色办公", type: "2" },
        { name: "红色工艺", type: "3" },
      ],
      id: "",
      name: "",
      type: "1",
      info: "",
      imgList: [],
      imgIndex: -1,
      coverImg: "",
      uploadType: "",
      videoList: [],
      videoName: "",
      progress: 0,
    };
  },
  mounted() {
    if (!store.getters.jwt) {
      this.$router.replace("/");
      return;
    }
    // if (!store.getters.userInfo.user_name) {
    //   this.mineVisible = true;
    //   return;
    // }

    this.id = this.$route.query.id;
    if (this.id) {
      worksInfo({ id: this.id }).then((res) => {
        this.name = res.data.title;
        this.type = res.data.category_id;
        this.info = res.data.content;
        this.coverImg = res.data.face_file;
        this.imgList = res.data.img_list;
        if (res.data.video_list && res.data.video_list.length > 0) {
          this.videoList = res.data.video_list;
          let arr = this.videoList[0].split("/");
          this.videoName = arr[arr.length - 1];
          this.progress = 100;
        }
      });
    } else {
      let entries = store.getters.entries;
      if (entries && entries != "{}") {
        this.id = entries.id;
        this.name = entries.idea_name;
        this.type = entries.category_id;
        this.info = entries.content;
        this.coverImg = entries.face_file;
        this.imgList = entries.img_list;

        if (entries.video_list && entries.video_list.length > 0) {
          this.videoList = entries.video_list;
          let arr = this.videoList[0].split("/");
          this.videoName = arr[arr.length - 1];
          this.progress = 100;
        }
      }
    }
  },
  methods: {
    uploadVideo() {
      this.uploadType = "video";
      this.$refs.upload.select("video", 500 * 1024 * 1024);
    },
    uploadImg(index) {
      this.imgIndex = index;
      this.uploadType = "image";
      this.$refs.upload.select();
    },
    delImg(index){
      this.imgList.splice(index,1);
    },
    uploadCover() {
      this.uploadType = "coverImg";
      this.$refs.upload.select();
    },
    delCover() {
     this.coverImg = "";
    },
    uploadSuccess(file, name) {
      if (this.uploadType == "video") {
        if (name) {
          this.videoName = name;
        } else {
          let arr = file.split("/");
          this.videoName = arr[arr.length - 1];
        }
        this.videoList = [];
        this.videoList.push(file);
        this.progress = 100;
      } else if (this.uploadType == "image") {
        if (this.imgIndex == -1) {
          this.imgList.push(file);
        } else {
          this.imgList[this.imgIndex] = file;
        }
      } else if (this.uploadType == "coverImg") {
        this.coverImg = file;
      }
    },
    progressChange(progress) {
      this.progress = progress;
    },
    mine() {
      this.mineVisible = false;
      this.$router.replace("/setting");
    },
    back() {
      this.dialogVisible = false;
      this.$router.back();
    },
    submit(type) {
      if (!this.name) {
        this.$message("请输入作品名称");
        return;
      }
      if (!this.info) {
        this.$message("请输入作品介绍");
        return;
      }
      if (this.imgList.length <= 0) {
        this.$message("请上传图片");
        return;
      }
      // if (this.videoList.length <= 0) {
      //   this.$message("请上传视频");
      //   return;
      // }

      if (!this.coverImg) {
        this.$message("请上传封面");
        return;
      }

      let params = {
        idea_name: this.name,
        category_id: this.type,
        content: this.info,
        video_list: this.videoList,
        face_file: this.coverImg,
        img_list: this.imgList,
      };
      if (this.id) {
        params.id = this.id;
      }
      if (type == "preview") {
        store.commit("previewEntries", params);
        this.$router.push("/detail?type=1");
      } else {
        saveEntries(params).then(() => {
          store.commit("previewEntries", "");
          this.dialogVisible = true;
        });
      }
    },
  },
};
</script>
<style lang="less">
.upload {
  .el-input__inner {
    height: 2.2rem;
    line-height: 2.2rem;
    background: white;
    border: 1px solid #707070;
    border-radius: 0;
    font-size: 1rem;
  }

  .el-textarea__inner {
    height: 13.2rem;
    border: 1px solid #707070;
    border-radius: 0;
    font-size: 1rem;
  }

  .el-progress {
    width: 15rem;
    margin-left: 0.6rem;
  }
}
</style>
<style lang="less" scoped>
.upload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title-box {
    height: 9.1rem;
    background: @mainColor;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      color: #FCEAA7;
      font-size: 2.1rem;
      margin-left: 2.5rem;
    }
  }

  .box {
    width: 67rem;
    background: #f7f7f7;
    padding: 3rem 5rem 0 5rem;
    margin-top: 3rem;

    .cell {
      display: flex;
      justify-content: start;
      margin-bottom: 1.2rem;
      min-height: 2.2rem;

      .title {
        width: 6rem;
        height: 2.2rem;
        line-height: 2.2rem;
      }

      .input {
        flex: 1;
        overflow: hidden;
      }
      .radio {
        height: 2.2rem;
        line-height: 2.2rem;
      }
      .textarea {
        flex: 1;
        overflow: hidden;
        margin-bottom: 1.8rem;
      }
    }

    .video {
      display: flex;
      justify-content: start;
      align-items: center;
      height: auto;

      .btn {
        width: 15rem;
        height: 3rem;
        line-height: 3rem;
        text-align: center;
        background: #FCE393;
        font-size: 1.3rem;
        color:  #A40607;
      }
      .name {
        margin-left: 1.1rem;
        font-size: 1.5rem;
        flex: 1;
        color: #000;
        max-width: 25rem;
        overflow: hidden;
        word-break: break-all;
      }

      .progress {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 4.3rem;
        font-size: 1.3rem;
        color: #707070;

        .close {
          width: 3rem;
          height: 3rem;
          margin-left: 1rem;
        }
      }
    }

    .tip {
      margin-top: 3.3rem;
      margin-bottom: 3rem;
      font-size: 1rem;
      color: #707070;
    }

    .img-box {
      display: flex;
      align-items: center;
      justify-content: start;
      flex-wrap: wrap;
      margin-bottom: 0.9rem;

      .image {
        margin-bottom: 2.1rem;
        margin-right: 2rem;
        width: 9.1rem;
        height: 9.1rem;
        position: relative;
        .img {
          width: 100%;
          height: 100%;
        }
        .del {
          position: absolute;
          width: 2rem;
          height: 2rem;
          padding: 0.5rem;
          top: 0;
          right: 0;
        }
      }

      .add-box {
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom: 2.1rem;
        margin-right: 2rem;

        .add {
          width: 9.1rem;
          height: 9.1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          border: 1px solid #707070;
          img {
            width: 2rem;
            height: 2rem;
          }
          .text {
            margin-top: 1rem;
            color: #707070;
            font-size: 1.1rem;
          }
        }

        .add-tip {
          margin-left: 1.9rem;
          color: #707070;
          font-size: 1rem;
        }
      }
    }
  }

  .btn-box {
    margin: 3rem 0 4.8rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn1 {
      width: 10.8rem;
      height: 3rem;
      text-align: center;
      line-height: 3rem;
      background: #FCE393;
      color: #A40607;
      margin: 0 2.8rem;
      font-size: 1.3rem;
    }

    .btn2 {
      width: 10.8rem;
      height: 3rem;
      margin: 0 2.8rem;
      text-align: center;
      line-height: 3rem;
      background: #c6c6c6;
      color: white;
      font-size: 1.3rem;
    }
  }
}
</style>
