<template>
  <wrapper-page type="mine" :upload="false">
    <div class="setting">
      <div class="title-box">
        <div class="title">账号资料</div>
      </div>

      <div class="text">基本资料</div>
      <div class="box">
        <div class="cell">
          <div class="name">用户名</div>
          <el-input
            class="input"
            v-model="name"
            maxlength="8"
            placeholder="请输入用户名"
          ></el-input>
        </div>

        <div class="cell">
          <div class="name">个人简介</div>
          <el-input
            class="input"
            type="textarea"
            maxlength="200"
            show-word-limit
            placeholder="请输入个人简介"
            v-model="info"
          >
          </el-input>
        </div>
      </div>
      <div class="btn-box">
        <div class="btn1" @click="saveInfo">确定</div>
        <div class="btn2" @click="back">取消</div>
      </div>
      <div class="text">修改账号</div>
      <div class="box">
        <div class="cell">
          <div class="name">新手机号</div>
          <el-input
            class="input"
            v-model="mobile"
            maxlength="11"
            placeholder="请输入新手机号"
          ></el-input>
        </div>

        <div class="cell">
          <div class="name">短信验证码</div>
          <el-input
            class="input"
            v-model="code"
            maxlength="6"
            placeholder="请输入短信验证码"
          ></el-input>
          <div class="code" @click="getCode">{{ codeText }}</div>
        </div>
      </div>

      <div class="btn-box">
        <div class="btn1" @click="submit">确定</div>
        <div class="btn2" @click="back">取消</div>
      </div>
    </div>

    <el-dialog :visible="dialogVisible" width="30%" center :show-close="false">
      <div class="dialog-text">手机号码修改成功，请重新登录</div>
      <div slot="footer" class="dialog-btn" @click="toLogin">重新登录</div>
    </el-dialog>
  </wrapper-page>
</template>

<script>
import WrapperPage from "../components/wrapperPage.vue";

import { saveInfo, smsCode, updateMobile } from "../api/api";
import store from "@/store";
var timer;
export default {
  components: {
    WrapperPage,
  },

  data() {
    return {
      dialogVisible: false,
      name: "",
      info: "",
      mobile: "",
      code: "",
      time: 60,
      codeText: "获取短信验证",
      isTime: false,
      userInfo: store.getters.userInfo,
    };
  },

  mounted() {
    if (!store.getters.jwt) {
      this.$router.replace("/");
      return;
    }
    this.name = this.userInfo.user_name;
    this.info = this.userInfo.introduction;
  },
  methods: {
    back() {
      this.$router.back();
    },
    saveInfo() {
      if (!this.name) {
        this.$message("请输入用户名");
        return;
      }
      if (!this.info) {
        this.$message("请输入个人简介");
        return;
      }
      saveInfo({
        user_name: this.name,
        introduction: this.info,
      }).then(() => {
        this.$message.success("修改成功");
        this.userInfo.user_name = this.name;
        this.userInfo.introduction = this.info;
        store.commit("saveUserInfo", this.userInfo);
      });
    },
    toLogin() {
      this.dialogVisible = false;
      this.$router.push("/login");
    },
    getCode() {
      if (!this.mobile) {
        this.$message("请输入手机号");
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
        this.$message("手机号格式错误");
        return;
      }
      if (this.isTime) {
        return;
      }

      if (timer) {
        clearInterval(timer);
      }
      this.time = 60;
      this.isTime = true;
      timer = setInterval(() => {
        if (this.time <= 0) {
          clearInterval(timer);
          this.isTime = false;
          this.time = 60;
          this.codeText = "获取短信验证";
        } else {
          this.time--;
          this.codeText = this.time + " S";
        }
      }, 1000);

      smsCode({ mobile: this.mobile })
        .then(() => {
          this.$message.success("发送成功");
          // clearInterval(timer);
          // this.isTime = false;
          // this.time = 60;
          // this.codeText = "获取短信验证";
        })
        .catch(() => {
          clearInterval(timer);
          this.isTime = false;
          this.time = 60;
          this.codeText = "获取短信验证";
        });
    },
    submit() {
      if (!this.mobile) {
        this.$message("请输入手机号");
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
        this.$message("手机号格式错误");
        return;
      }
      if (!this.code) {
        this.$message("请输入验证码");
        return;
      }
      updateMobile({ mobile: this.mobile, code: this.code }).then(() => {
        store.commit("routerUrl", "/");
        this.dialogVisible = true;
      });
    },
  },
};
</script>
<style lang="less">
.setting {
  .el-input__inner {
    height: 2.2rem;
    line-height: 2.2rem;
    background: white;
    border: 1px solid #707070;
    border-radius: 0;
    font-size: 1rem;
  }

  .el-textarea__inner {
    height: 13.2rem;
    border: 1px solid #707070;
    border-radius: 0;
    font-size: 1rem;
  }
}
</style>
<style lang="less" scoped>
.setting {
  .title-box {
    height: 9.1rem;
    background: @mainColor;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      color: white;
      font-size: 2.1rem;
      margin-left: 2.5rem;
    }
  }

  .text {
    color: #000000;
    font-size: 1.3rem;
    width: 67rem;
    margin: 3rem auto 0 auto;
  }
  .box {
    width: 67rem;
    background: #f7f7f7;
    padding: 2rem 0 1rem 0;
    margin: 1.25rem auto 0 auto;

    .cell {
      display: flex;

      padding: 0 4.5rem 0 4.5rem;
      justify-content: space-between;
      margin-bottom: 1.1rem;
      min-height: 2.2rem;

      .name {
        width: 8.1rem;
        height: 2.2rem;
        line-height: 2.2rem;
        font-size: 1.3rem;
        color: #707070;
      }

      .input {
        flex: 1;
        overflow: hidden;
        color: #707070;
      }
      .code {
        width: 8.2rem;
        text-align: center;
        height: 2.2rem;
        line-height: 2.2rem;
        border: 1px solid #707070;
        background: #ebebeb;
        font-size: 1rem;
        color: #707070;
        margin-left: 1.5rem;
      }
    }
  }

  .btn-box {
    margin: 3rem 0 4.8rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn1 {
      width: 10.8rem;
      height: 3rem;
      text-align: center;
      line-height: 3rem;
      background: #457ad0;
      color: white;
      margin: 0 2.8rem;
      font-size: 1.3rem;
    }

    .btn2 {
      width: 10.8rem;
      height: 3rem;
      margin: 0 2.8rem;
      text-align: center;
      line-height: 3rem;
      background: #c6c6c6;
      color: white;
      font-size: 1.3rem;
    }
  }
}
</style>
