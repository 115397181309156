<template>
  <wrapper-page type="entries" :upload="false">
    <div class="detail">
      <div class="title-box">
        <div v-if="type" class="preview">预览</div>
        <div class="title">
          {{ info.title }}<span>作者 | {{ info.user_name }}</span>
        </div>
      </div>

      <div class="box">
        <div class="top">
          <img class="img" :src="info.face_file"/>
          <div class="content">
            <div class="title-con">
              <div class="title">
                <div class="arrow"></div>
                {{ info.title }}
              </div>

<!--              <div v-if="id && !type" class="love">{{ info.praise_count }}</div>-->
            </div>
            <div class="info">{{ info.content }}</div>
          </div>
        </div>

        <video
          class="video"
          :src="item"
          v-for="(item, index) in info.video_list"
          :key="'video' + index"
          controls="controls"
          type="video/mp4"
        ></video>

        <el-image
          class="image"
          :src="item"
          v-for="(item, index) in info.img_list"
          :key="'image' + index"
        ></el-image>

        <template v-if="id">
<!--          <img-->
<!--            v-if="!type"-->
<!--            class="praise"-->
<!--            src="../assets/img/praise.png"-->
<!--            alt=""-->
<!--            @click="praise"-->
<!--          />-->
        </template>

        <div class="btn-box" v-else>
          <div class="btn1" @click="submit">发布作品</div>
          <div class="btn2" @click="back">返回</div>
        </div>
      </div>
    </div>
    <el-dialog :visible="dialogVisible" width="30%" center :show-close="false">
      <div class="dialog-text">作品已发布，请等待审核</div>
      <div slot="footer" class="dialog-btn" @click="back">返回</div>
    </el-dialog>
  </wrapper-page>
</template>

<script>
import WrapperPage from "../components/wrapperPage.vue";
import { worksInfo, praise, saveEntries } from "../api/api";
import store from "@/store";
export default {
  components: {
    WrapperPage,
  },

  data() {
    return {
      dialogVisible: false,
      id: "",
      type: "", //1预览
      info: {
        id: "",
        category_id: "",
        title: "",
        content: "",
        praise_count: "0",
        praise: "0",
        video_list: [],
        face_file: "",
        user_name: "",
        category: "",
        img_list: [],
      },
      params: {
        id: "",
        idea_name: "",
        category_id: "",
        content: "",
        video_list: "",
        face_file: "",
        img_list: "",
      },
    };
  },
  mounted() {

    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    if (this.id) {
      worksInfo({ id: this.id }).then((res) => {
        this.info = res.data;
      });
    } else {
      this.params = store.getters.entries;
      if(this.params&&this.params!="{}"){
        this.info = this.params;
        this.info.title = this.params.idea_name;
        this.info.user_name = store.getters.userInfo.user_name;
      }
    
    }
  },
  methods: {
    back() {
      this.$router.back();
    },
    praise() {
      praise({ id: this.id }).then((res) => {
        this.$message.success(res.data.msg);
        if (this.info.praise == 0) {
          this.info.praise_count = parseInt(this.info.praise_count) + 1;
          this.info.praise = 1;
        } else {
          this.info.praise_count = parseInt(this.info.praise_count) - 1;
          this.info.praise = 0;
        }
      });
    },
    submit() {
      if(!this.params||this.params=="{}"){
        this.$message("参数错误");
        return;
      }
      saveEntries(this.params).then(() => {
        this.dialogVisible = true;
        store.commit("previewEntries", "");
      });
    },
  },
};
</script>
<style lang="less" scoped>
.detail {
  .title-box {
    height: 9.1rem;
    background: @mainColor;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;

    .preview {
      color: white;
      font-size: 1.8rem;
      margin-left: 2rem;
      height: 5rem;
      width: 5rem;
      border-radius: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.7);
    }

    .title {
      color: white;
      font-size: 2.1rem;
      margin-left: 2rem;
      span {
        font-size: 1.2rem;
        margin-left: 1.6rem;
      }
    }
  }

  .box {
    width: 65rem;
    margin: 5rem auto;

    .top {
      display: flex;
      justify-content: space-between;

      .img {
        width: 22.5rem;
        object-fit: contain;
      }

      .content {
        flex: 1;
        margin-left: 5rem;

        .title-con {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .title {
            color: #000000;
            font-size: 2.2rem;
            display: flex;
            justify-content: start;
            align-items: center;
            .arrow {
              width: 0;
              height: 0;
              border-top: 0.8rem solid transparent;
              border-left: 1.1rem solid @mainColor;
              border-bottom: 0.8rem solid transparent;
              margin-right: 0.9rem;
            }
          }

          .love {
            width: 3.5rem;
            height: 2.3rem;
            line-height: 2.3rem;
            padding-left: 2.8rem;
            text-align: center;
            font-size: 1.25rem;
            color: #707070;
            background: url("../assets/img/love_bg.png") no-repeat;
            background-size: 100% 100%;
          }
        }

        .info {
          color: #707070;
          font-size: 1.25rem;
          margin-top: 1.4rem;
        }
      }
    }

    .video {
      margin-top: 3.7rem;
      width: 100%;
      height: 32.1rem;
    }

    .image {
      margin-top: 2.6rem;
      width: 100%;
      height: 32.1rem;
    }

    .praise {
      display: block;
      width: 13.8rem;
      height: 3.15rem;
      margin: 3rem auto;
    }

    .btn-box {
      margin: 3rem 0 4.8rem 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .btn1 {
        width: 10.8rem;
        height: 3rem;
        text-align: center;
        line-height: 3rem;
        background: #457ad0;
        color: white;
        margin: 0 2.8rem;
        font-size: 1.3rem;
      }

      .btn2 {
        width: 10.8rem;
        height: 3rem;
        margin: 0 2.8rem;
        text-align: center;
        line-height: 3rem;
        background: #c6c6c6;
        color: white;
        font-size: 1.3rem;
      }
    }
  }
}
</style>
