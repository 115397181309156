<template>
  <div class="container">
    <img class="top" src="https://culture-fzwc.oss-cn-beijing.aliyuncs.com/index.png" />

    <div class="form-btn" @click="signUp()" >立即报名</div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  mounted(){

  },
  methods: {
    signUp() {
      this.$router.replace('/sign');
    }
  },
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "NotoSansHans";
  src: url("../assets/font/NotoSansHans-Regular.ttf");
}

.container {
  background-size: 100% 100%;
  font-size: 0.75rem;
  width: 100%;
  height: 100vh;
  position: relative;
  margin: 0;
  padding: 0;
  overflow: auto;
  font-family: "NotoSansHans", "PingFang SC", "Microsoft Yahei", sans-serif;
  .form-btn{
    position: absolute;
    left: 20px;
    right: 20px;
    top: 210px;
    margin: 30px 10px;
    height: 56px;
    line-height: 56px;
    background: url("../assets/img/bg-btn1.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
    font-size: 22px;
    color: #A40607;
    letter-spacing: 2px;
  }
}
.container .top {
  width: 100%;
}

</style>
