<template>
  <wrapper-page type="entries">
    <div class="entries">
      <div class="tab-box">
        <div
          class="tab"
          :class="{ active: tab == index }"
          @click="selectTab(index)"
          v-for="(item, index) in tabList"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <template v-if="tab == 2">
<!--        <img class="prize-img" src="../assets/img/prize_img1.png" alt="" />-->
        <div class="prize-box">
          <template v-for="(item, index) in list">
          <div
            class="item"
            v-if="item.award_type === '1'"
            :key="index"
            @click="detail(item.id)"
          >
            <el-image
              class="img"
              :src="item.face_file"
              fit="contain"
            ></el-image>
            <div class="name-box">{{ item.title }}</div>

            <div class="cover" @click="detail(item.id)">
              <div class="tag">作品名称</div>
              <div class="name">{{ item.title }}</div>
              <div class="look">点击观看</div>
            </div>
          </div>
          </template>
        </div>
<!--        <img class="prize-img" src="../assets/img/prize_img2.png" alt="" />-->
        <div class="prize-box">

          <template v-for="(item, index) in list">
          <div
            class="item"
            v-if="item.award_type === '2'"
            :key="index"
            @click="detail(item.id)"
          >
            <el-image
              class="img"
              :src="item.face_file"
              fit="contain"
            ></el-image>
            <div class="name-box">{{ item.title }}</div>
            <div class="filter"></div>
            <div class="cover" @click="detail(item.id)">
              <div class="tag">作品名称</div>
              <div class="name">{{ item.title }}</div>
              <div class="look">点击观看</div>
            </div>
          </div>
          </template>

        </div>
<!--        <img class="prize-img" src="../assets/img/prize_img3.png" alt="" />-->
        <div class="prize-box">

          <template v-for="(item, index) in list">
          <div
            class="item"
            v-if="item.award_type === '3'"
            :key="index"
            @click="detail(item.id)"
          >
            <el-image
              class="img"
              :src="item.face_file"
              fit="contain"
            ></el-image>
            <div class="name-box">{{ item.title }}</div>

            <div class="cover" @click="detail(item.id)">
              <div class="tag">作品名称</div>
              <div class="name">{{ item.title }}</div>
              <div class="look">点击观看</div>
            </div>
          </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="box">
          <div class="type-box">
            <div
              class="type"
              :class="{ active: type == item.type }"
              v-for="item in typeList"
              :key="item.type"
              @click="selectType(item.type)"
            >
              {{ item.name }}
            </div>
          </div>

          <div class="search-box">
            <el-input
              class="input"
              placeholder="请输入内容"
              v-model="keyword"
            ></el-input>
            <div class="btn" @click="search">搜索</div>
          </div>
        </div>

        <template v-if="isSearch">
          <div class="result" v-if="total > 0">
            共搜索到<span>{{ total }}</span
            >条结果
          </div>
          <div class="result" v-else>没有找到符合条件的搜索结果</div>
        </template>
        <div class="list-box">
          <div
            class="item"
            v-for="(item, index) in list"
            :key="index"
            @click="detail(item.id)"
          >
            <el-image
              class="img"
              :src="item.face_file"
              fit="contain"
            ></el-image>
            <div class="name">{{ item.title }}</div>
          </div>
        </div>

        <div class="page-box" v-if="total>0">
          <el-pagination
            class="pagination"
            layout="prev, pager, next"
            :total="total"
            :current-page="page"
            :page-size="9"
            @current-change="changePage"
          >
          </el-pagination>
        </div>
      </template>
    </div>
  </wrapper-page>
</template>

<script>
import WrapperPage from "../components/wrapperPage.vue";
import { searchWorks } from "../api/api";
export default {
  components: {
    WrapperPage,
  },

  data() {
    return {
      tab: 0,
      tabList: ["参 赛 作 品", "入 围 作 品", "获 奖 作 品"],
      type: "0",
      typeList: [
        {
          name: "全部",
          type: "0",
        },
        {
          name: "红色纹样",
          type: "1",
        },
        {
          name: "红色办公",
          type: "2",
        },
        {
          name: "红色工艺",
          type: "3",
        },
      ],
      keyword: "",
      isSearch: false,
      page: 1,
      total: 0,
      list: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    selectTab(pos) {
      this.tab = pos;
      this.page = 1;
      this.loadData();
    },
    selectType(type) {
      this.type = type;
      this.page = 1;
      this.loadData();
    },
    search() {
      this.isSearch = true;
      this.loadData();
    },
    changePage(page) {
      this.page = page;
      this.loadData();
    },
    loadData() {
      let status = this.tab + 1
      let params = {
        title: status === 3 ? '' : this.keyword,
        status: status,
        category_id: this.type,
        page: this.page,
        limit : status === 3 ? 30 : 9
      };
      searchWorks(params).then((res) => {
        this.total = parseInt(res.data.total);
        this.page = parseInt(res.data.page);
        this.list = res.data.list;
      });
    },
    detail(id) {
      console.log(parseInt(id))
      this.$router.push("/detail?id=" + id);
    },
  },
};
</script>
<style>
.entries .el-input__inner {
  height: 2.5rem;
  line-height: 2.5rem;
  width: 21rem;
  background: #f7f7f7;
  border-radius: 1.25rem;
}
</style>
<style lang='less' scoped>
.entries {
  background: #ffffff;
  padding-bottom: 2rem;
  .tab-box {
    height: 4rem;
    width: 76.5rem;
    border-radius: 0.04rem;
    background: #f7f7f7;
    margin: 2.8rem auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .tab {
      flex: 1;
      height: 2.8rem;
      border-radius: 0.03rem;
      line-height: 2.8rem;
      text-align: center;
      font-size: 1.25rem;
      color: #707070;
      margin: 0 1.2rem;
      background: #e6e6e6;
    }

    .active {
      color: white;
      background: @mainColor;
    }
  }

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3rem auto 0 auto;
    width: 70rem;

    .type-box {
      display: flex;
      justify-content: center;
      align-items: center;

      .type {
        margin-right: 1.1rem;
        background: #b9b9b9;
        color: white;
        font-size: 1.25rem;
        width: 4.5rem;
        height: 1.9rem;
        line-height: 1.9rem;
        text-align: center;
        border-radius: 0.95rem;
      }

      .active {
        background: @mainColor;
      }
    }

    .search-box {
      display: flex;
      justify-content: center;
      align-items: center;

      .input {
        height: 2.5rem;
        width: 21rem;
        background: #f7f7f7;
        border-radius: 1.25rem;
      }

      .btn {
        height: 2.5rem;
        color: #457ad0;
        padding-left: 0.8rem;
        line-height: 2.5rem;
        font-size: 1.25rem;
      }
    }
  }

  .result {
    margin: 2.5rem auto 0 auto;
    width: 70rem;
    color: #000;
    font-size: 1.7rem;
    span {
      font-weight: bold;
    }
  }
  .list-box {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 3rem auto;
    flex-wrap: wrap;
    width: 70rem;

    .item {
      width: 21rem;
      margin-bottom: 1.2rem;
      margin-right: 3.5rem;

      .img {
        width: 21rem;
        height: 9.2rem;
      }
      .name {
        margin-top: 1rem;
        color: #000000;
        font-size: 1.4rem;
        text-align: center;
        span {
          font-size: 1rem;
        }
      }
    }
    .item:nth-child(3n + 3) {
      margin-right: 0;
    }
  }

  .page-box {
    margin: 0 auto;
    width: 70rem;
    height: 3rem;

    .pagination {
      float: right;
    }
  }

  .prize-img {
    display: block;
    height: 4.7rem;
    margin: 2.8rem auto 2rem auto;
  }

  .prize-box {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 0 auto;
    flex-wrap: wrap;
    width: 76.5rem;

    .item {
      width: 24rem;
      height: 14.2;
      margin-bottom: 2.8rem;
      margin-right: 2.1rem;
      position: relative;

      .img {
        width: 100%;
        height: 10.6rem;
      }
      .name-box {
        color: white;

        height: 3.6rem;
        line-height: 3.6rem;
        font-size: 1.4rem;
        background: @mainColor;

        padding: 0 1.3rem;
      }

      .cover {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: none;
        background: #17466d7e;
        .tag {
          margin-top: 3rem;
          font-size: 1rem;
          color: white;
          text-align: center;
        }
        .name {
          margin-top: 0.1rem;
          font-size: 1.5rem;
          color: white;
          text-align: center;
        }

        .look {
          width: 7.5rem;
          height: 2.5rem;
          line-height: 2.5rem;
          border-radius: 1.25rem;
          text-align: center;
          color: white;
          font-size: 1.25rem;
          margin: 2.2rem auto 0 auto;
          background: rgba(255, 255, 255, 0.6);
        }
      }
    }
    .item:nth-child(3n + 3) {
      margin-right: 0;
    }
    .item:hover {
      .cover {
        display: block;
      }
    }
  }
}
</style>