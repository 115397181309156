<template>
  <input
    style="display: none"
    id="upload"
    type="file"
    name="file"
    @change="inputChange"
  />
</template>

<script>
import { upload } from "../api/upload";

export default {
  name: "upload",
  data() {
    return {
      accept: "image",
      maxSize: 10 * 1024 * 1024,
    };
  },
  mounted() {
    document.getElementById("upload").addEventListener(
      "click",
      function() {
        this.value = "";
      },
      false
    );
  },
  methods: {
    select(accept = "image", maxSize = 10 * 1024 * 1024) {
      this.accept = accept;
      this.maxSize = maxSize;
      document.getElementById("upload").click();
    },
    inputChange(event) {
      let filepath = document.getElementById("upload").value;
      let extStart = filepath.lastIndexOf(".");
      let ext = filepath.substring(extStart, filepath.length).toLowerCase();
      if (filepath === "" || filepath === null) {
        return;
      }
      let file = event.target.files[0];
      if (file.size > this.maxSize) {
        if (this.maxSize >= 1024 * 1024) {
          let max = parseInt(this.maxSize / 1024 / 1024);
          this.$message("文件大小不能大于" + max + "M");
        } else if (this.maxSize >= 1024) {
          let max = parseInt(this.maxSize / 1024);
          this.$message("文件大小不能大于" + max + "KB");
        } else {
          this.$message("文件大小不能大于" + this.maxSize + "B");
        }
        return;
      }

      if (this.accept == "video") {
        if (ext != ".mov" && ext != ".mp4" && ext != ".wmv") {
          this.$message("仅限于上传  mov，mp4，wmv格式的文件");
          return;
        }
        this.$emit("change", filepath);
        upload(file, ext, (path) => {
          this.$emit("change", path, filepath);
        }, (progress)=>{
          this.$emit("progress", progress);
        });
        return;
      } else if (this.accept == "rar") {
        if (ext != ".zip" && ext != ".rar") {
          this.$message("仅限于上传 zip，rar 格式的文件");
          return;
        }
         this.$emit("change", filepath);
        upload(file, ext, (path) => {
          this.$emit("change", path);
        });
        return;
      }

      if (
        ext != ".jpg" &&
        ext != ".jpeg" &&
        ext != ".gif" &&
        ext != ".bmp" &&
        ext != ".png"
      ) {
        this.$message("仅限于上传 jpeg，jpg，png，bmp，gif 格式的文件");
        return;
      }
      //  this.$emit("change", window.URL.createObjectURL(file));
      upload(file, ext, (path) => {
        this.$emit("change", path);
      });

      // let src = window.URL.createObjectURL(file);
      // this.compressImg(src, (base64) => {
      
      
      // });
    },
    compressImg: function(imgSrc, callback) {
      let img = new Image();
      img.onload = function() {
        //压缩的大小
        let max_width = 1920;

        let canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;
        if (width > max_width) {
          height = Math.round((height *= max_width / width));
          width = max_width;
        }

        canvas.width = width;
        canvas.height = height;

        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        //压缩率
        let base64 = canvas.toDataURL("image/jpeg", 0.3);
        callback(base64);
      };
      img.src = imgSrc;
    },
  },
};
</script>

<style scoped></style>
