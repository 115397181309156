import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    jwt: localStorage.jwt,
    routerUrl: "/upload",
    uploadSign: {
      accessid: "",
      host: "",
      policy: "",
      signature: "",
      callback: "",
      dir: "",
      expire: 0,
    },
    entries: {
      idea_name: "",
      category_id: "1",
      content: "",
      video_list: [],
      face_file: "",
      img_list: [],
      id: "",
    },
    userInfo: localStorage.userInfo?JSON.parse(localStorage.userInfo):{},
  },
  mutations: {
    routerUrl: (state, value) => {
      state.routerUrl = value;
    },
    previewEntries: (state, value) => {
      state.entries = value;
    },
    saveJwt: (state, value) => {
      state.jwt = value;
      localStorage.jwt = value;
    },
    uploadSign: (state, value) => {
      state.uploadSign = value;
    },
    saveUserInfo: (state, value) => {
      state.userInfo = value;
      localStorage.userInfo = JSON.stringify(value);
    },
    logout(state){
      state.jwt = "";
      localStorage.jwt = "";
      state.userInfo = "";
      localStorage.userInfo = "{}";
      state.routerUrl = "/";
      state.uploadSign = {};
    }
  },
  actions: {},

  getters: {
    jwt: (state) => state.jwt,
    uploadSign: (state) => state.uploadSign,
    routerUrl: (state) => state.routerUrl,
    entries: (state) => state.entries,
    userInfo: (state) => state.userInfo,
  },
});
