<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  mounted() {
    if (this.$route.path != "/") {
      document.querySelector("#app").scrollTop = 0;
      document.querySelector("#app").scroll(0, 0);
      window.scrollTo(0, 0);
      window.scrollTop = 0;
    }
  },
  created() {
    // let channelId = this.getQueryString("channel_id");
    // if (!channelId) {
    //   channelId = 1;
    // }
    // channel(channelId).then().catch();

    var resize = "orientationchange" in window ? "orientationchange" : "resize";
    window.addEventListener(resize, this.resize, false);
    window.document.addEventListener("DOMContentLoaded", this.resize, false);
    this.resize();
  },
  methods: {
    resize() {
      let docElm = window.document.documentElement;
      let clientWidth = docElm.clientWidth;
      let clientHeight = docElm.clientHeight;
      // console.log("clientWidth==" + clientWidth);
      // console.log("clientHeight==" + clientHeight);
      if (clientWidth < clientHeight * 1.5 && this.$route.meta.H5==true) {
        // console.log("path==" + this.$route.path);
        docElm.style.fontSize = 20 * (clientWidth / 375) + "px";
      } else {
        if (clientWidth < 1152) {
          docElm.style.fontSize = "12px";
        } else {
          docElm.style.fontSize = 20 * (clientWidth / 1920) + "px";
        }
      }

      // if (document.body.clientWidth <script document.body.clientHeight * 1.5) {

      // if (window.location.host == 'www.pophit.cn'){
      //   window.location.href = "//h5.pophit.cn";
      // }else{
      //   window.location.href = "//game-h5.codeqm.com";
      // }
      // }
    },
    getQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return decodeURIComponent(r[2]);
      return "";
    },
  },
};
</script>

<style lang="less">
@import "assets/style/style.less";
</style>
