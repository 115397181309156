import axios from "axios"
import store from '@/store'
import {Message} from 'element-ui';
var uploadSign;

const convertBase64ToBlob = (base64) => {
  let base64Arr = base64.split(',');
  let imgtype = '';
  let base64String = '';
  if (base64Arr.length > 1) {
    //如果是图片base64，去掉头信息
    base64String = base64Arr[1];
    imgtype = base64Arr[0].substring(base64Arr[0].indexOf(':') + 1, base64Arr[0].indexOf(';'));
  }
  // 将base64解码
  let bytes = atob(base64String);
  //let bytes = base64;
  let bytesCode = new ArrayBuffer(bytes.length);
  // 转换为类型化数组
  let byteArray = new Uint8Array(bytesCode);

  // 将base64转换为ascii码
  for (let i = 0; i < bytes.length; i++) {
    byteArray[i] = bytes.charCodeAt(i);
  }

  // 生成Blob对象（文件对象）
  return new Blob([bytesCode], {type: imgtype});
};

const random_string = (len) => {
  len = len || 32;
  let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  let maxPos = chars.length;
  let pwd = '';
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}

const get_suffix = (filename) => {
  let pos = filename.lastIndexOf('.')
  let suffix = ''
  if (pos != -1) {
    suffix = filename.substring(pos)
  }
  return suffix;
}

const uploadFile = (file, filename, cb, progress) => {
  if (typeof (file) == "string") {
    file = convertBase64ToBlob(file);
  }
  let saveName = random_string() + get_suffix(filename);
  const data = new FormData();
  data.append('OSSAccessKeyId', uploadSign.accessid);
  data.append('policy', uploadSign.policy);
  data.append('signature', uploadSign.signature);
  data.append('callback', uploadSign.callback);
  data.append('key', uploadSign.dir + "/" + saveName);
  data.append('success_action_status', 200);
  data.append('file', file, saveName);

  axios({
    timeout: 0,
    url: uploadSign.host,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: progressEvent => {
      let value = (progressEvent.loaded / progressEvent.total * 100 | 0) 
      if (progress && typeof (progress) == 'function') {
        progress(value);
      }
    },
    data
  }).then((res)=>{
    // console.log(res);
    if (res.status === 200) {
      let fileUrl = uploadSign. host + '/' + uploadSign.dir + '/' + saveName;
      if (cb && typeof (cb) == 'function') {
        cb(fileUrl);
      }
    }
  }).catch(()=>{
    Message({
      showClose: true,
      message: "上传失败",
      type: 'error'
    })
  })
};

export const upload = (file, filename, cb, progress) => {
  uploadSign = store.getters.uploadSign;
  let now = parseInt(new Date().getTime() / 1000);
  let expire = parseInt(uploadSign.expire);
  console.log("now=="+now)
  console.log("expire=="+expire)
  if (!expire || (expire + 5 * 60 < now) ) {
    axios.post("/Sapi/alioss/getsign", {})
        .then(res => {
          uploadSign = res.data;
          store.commit("uploadSign", res.data)
          uploadFile(file, filename, cb,progress)
        })
  } else {
    uploadFile(file, filename, cb, progress);
  }
}

