<template>
  <wrapper-page type="mine" :upload="false">
    <div class="mine">
      <div class="title-box">
        <div class="title">我的作品</div>

        <div class="upload" @click="upload()">上传作品</div>
      </div>

      <div class="type-box">
        <div
          class="type"
          :class="{ active: type == item.type }"
          v-for="item in typeList"
          :key="item.type"
          @click="selectType(item.type)"
        >
          {{ item.name }}
        </div>
      </div>

      <template>
        <div class="list-box">
          <div
            class="item"
            v-for="(item, index) in list"
            :key="index"
            @click="detail(item)"
          >
            <el-image
              class="img"
              :src="item.face_file"
              fit="contain"
            ></el-image>
            <div class="name">{{ item.title }}</div>
            <div class="status status1" v-if="item.status == 1">审核中</div>
            <div class="status status2" v-if="item.status == 3">审核失败</div>
            <div class="status status3" v-if="item.status == 4">已下架</div>

            <div class="cover" v-if="item.status != 2">
              <template v-if="item.status == 1">
                <div class="tag">审核中</div>
                <div class="reason">点击预览</div>
              </template>
              <template v-if="item.status == 3">
                <div class="tag">失败原因</div>
                <div class="reason">{{ item.audit_result }}</div>
              </template>
              <template v-if="item.status == 4">
                <div class="tag">下架原因</div>
                <div class="reason">{{ item.audit_result }}</div>
              </template>
              <div class="btn-box">
                <div class="delete" @click.stop="del(item.id)">删除</div>
                <div class="edit" @click.stop="edit(item.id)">编辑</div>
              </div>
            </div>

          </div>
        </div>

        <div class="page-box" v-if="total>0">
          <el-pagination
            class="pagination"
            layout="prev, pager, next"
            :total="total"
            :current-page="page"
            :page-size="9"
            @current-change="changePage"
          >
          </el-pagination>
        </div>
      </template>

      <div class="result" v-if="list.length == 0">您还未上传过任何作品</div>
    </div>
  </wrapper-page>
</template>

<script>
import WrapperPage from "../components/wrapperPage.vue";
import { entriesList, deleteEntries } from "../api/api";
import store from "@/store";
export default {
  components: {
    WrapperPage,
  },

  data() {
    return {
      type: "0",
      typeList: [
        {
          name: "全部",
          type: "0",
        },
        {
          name: "审核中",
          type: "1",
        },
        {
          name: "审核通过",
          type: "2",
        },
        {
          name: "审核失败",
          type: "3",
        },
        {
          name: "已下架",
          type: "4",
        },
      ],
      total: 0,
      page: 1,
      list: [],
    };
  },
  mounted() {
    if (!store.getters.jwt) {
      this.$router.replace("/");
      return;
    }
    this.loadList();
  },
  methods: {
    loadList() {
      entriesList({ status: this.type, page: this.page, limit: 9 }).then(
        (res) => {
          this.total = parseInt(res.data.total);
          this.list = res.data.list;
        }
      );
    },
    changePage(page) {
      this.page = page;
      this.loadList();
    },
    selectType(type) {
      this.type = type;
      this.page = 1;
      this.loadList();
    },
    upload() {
      this.$router.push("/upload");
    },
    detail(item) {
      let url = "/detail?id=" + item.id;
      if (item.status != 2) {
        url += "&type=1";
      }
      this.$router.push(url);
    },
    edit(id) {
      this.$router.push("/upload?id=" + id);
    },
    del(id) {
      deleteEntries({ id: id }).then(() => {
        this.$message.success("删除成功")
        this.loadList();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.mine {
  padding-bottom: 3rem;
  .title-box {
    height: 9.1rem;
    background: @mainColor;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      color: white;
      font-size: 2.1rem;
      margin-left: 2.5rem;
    }

    .upload {
      width: 15rem;
      height: 3rem;
      display: flex;
      margin-right: 3.8rem;
      justify-content: center;
      align-items: center;
      color: @mainColor;
      font-size: 1.3rem;
      background: #ffffff;
    }
  }

  .type-box {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 2.7rem auto 0 auto;
    width: 70rem;

    .type {
      margin-right: 1.1rem;
      background: #b9b9b9;
      color: white;
      font-size: 1.25rem;
      padding: 0 1.5rem;
      height: 1.9rem;
      line-height: 1.9rem;
      text-align: center;
      border-radius: 0.95rem;
    }

    .active {
      background: @mainColor;
    }
  }
  .list-box {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 2rem auto;
    flex-wrap: wrap;
    width: 70rem;

    .item {
      width: 21rem;
      margin-bottom: 1.8rem;
      margin-right: 3.5rem;
      position: relative;

      .img {
        width: 21rem;
        height: 11.5rem;
      }
      .name {
        margin-top: 1rem;
        color: #000000;
        font-size: 1.4rem;
        span {
          font-size: 1rem;
        }
      }

      .status {
        position: absolute;
        top: 1.25rem;
        right: 0;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        z-index: 1;
        font-size: 1.1rem;
        padding: 0 1.25rem;

        border-radius: 1rem 0 0 1rem;
      }
      .status1 {
        color: @mainColor;
        background: #f9d52e;
      }
      .status2 {
        color: white;
        background: #f05c5c;
      }
      .status3 {
        color: white;
        background: #b9b9b9;
      }

      .cover {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        width: 100%;
        height: 11.5rem;
        display: none;
        background: #17466d7e;
        .tag {
          margin-top: 1rem;
          margin-left: 1rem;
          font-size: 1.25rem;
          color: white;
        }
        .reason {
          margin-top: 0.1rem;
          margin-left: 1rem;
          font-size: 1rem;
          color: white;
        }

        .btn-box {
          margin-top: 4rem;

          .edit {
            float: right;
            background: #f9d52e;
            height: 2rem;
            line-height: 2rem;
            text-align: center;
            width: 4.5rem;
            border-radius: 1rem;
            font-size: 1.25rem;
            margin-right: 1rem;
            color: @mainColor;
          }

          .delete {
            float: right;
            background: #b9b9b9;
            height: 2rem;
            line-height: 2rem;
            margin-right: 1.5rem;
            text-align: center;
            width: 4.5rem;
            border-radius: 1rem;
            font-size: 1.25rem;
            color: white;
          }
        }
      }
    }
    .item:nth-child(3n + 3) {
      margin-right: 0;
    }
    .item:hover {
      .cover {
        display: block;
      }
    }
  }
  .page-box {
    margin: 0 auto;
    width: 70rem;
    height: 3rem;

    .pagination {
      float: right;
    }
  }

  .result {
    font-size: 1.6rem;
    color: #707070;
    text-align: center;
    margin-top: 15rem;
  }
}
</style>
