<template>
  <div class=" baoming-page">
    <img class="banner" src="https://culture-fzwc.oss-cn-beijing.aliyuncs.com/sign_top.jpg" />
<!--    <img class="title-img" src="../assets/img/title.png" />-->

<!--    报名信息-->
    <div class="container">
      <div class="sec-box bg-img-wrap">
        <div class="title-wrap">
          <div class="title-box">报名信息</div>
        </div>
        <div class="form-box">
          <div class="form-item">
            <label class="label">姓名</label>
            <input
                class="input"
                v-model="name"
                placeholder="（必填）"
                maxlength="8"
            />
            <div class="error" v-if="errorName">{{ errorName }}</div>
          </div>
          <div class="form-item">
            <label class="label">电话</label>
            <input
                class="input"
                v-model="phone"
                placeholder="（必填）"
                maxlength="11"
            />
            <div class="error" v-if="errorPhone">{{ errorPhone }}</div>
          </div>
          <div class="form-item">
            <label class="label">邮箱</label>
            <input class="input" v-model="email" placeholder="（必填）" />
            <div class="error" v-if="errorEmail">{{ errorEmail }}</div>
          </div>
          <div class="form-item">
            <label class="label">所在单位</label>
            <input class="input" v-model="company" placeholder="（选填）" />
          </div>
          <div class="form-item">
            <label class="label">指导老师</label>
            <input class="input" v-model="teacher" placeholder="（选填）" />
          </div>
          <div class="select-title">参赛作品类别(多选)</div>
          <div class="select-list">
            <div
                class="item"
                v-for="(item, index) in selectList"
                :key="index"
                @click="check(index)"
            >
              <img
                  class="check"
                  v-if="item.checked"
                  src="../assets/img/checked1.png"
              />
              <img class="check" v-else src="../assets/img/uncheck1.png" />
              <div class="name">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="form-btn" @click="submit()" >立即报名</div>
      </div>
      <!--    温馨提示-->
      <div class="sec-box">
        <div class="title-wrap">
          <div class="title-box">温馨提示</div>
        </div>
        <div class="tip-text">
          1.如有疑问，请在上班时间致电咨询。<br />
          <p>联系人：王小平</p>
<!--          <img class="tel" src="../assets/img/tel.png" />-->
          <p>联系电话（传真）：<a
            href="tel:0591-87877778"
        >0591-87877778，</a></p>
          <p>17361605377</p>
          <p>工作日上班时间：</p><p>周一至周五 08:30-12:00、15:00-17:30</p>
        </div>
        <div class="tip-text">
          2.报名成功后，请登录邮箱查看如何上传作品。
          <p>（若信息有误，请重新报名）</p>
        </div>
        <div class="tip-text">3.扫描下方二维码，关注公众号，获取更多资讯。</div>
        <img class="qrcode" src="../assets/img/qrcode.jpg" />
      </div>
    </div>


<!--    <div class="success" v-if="showSuccess">-->
<!--      <div class="content">-->
<!--        <img class="ok" src="../assets/img/ok.png" alt="" />-->
<!--        <div class="text">恭喜你<br />报名成功</div>-->
<!--        <div class="know" @click="showSuccess = false">预祝佳绩</div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="success" v-show="showCopy">
      <div class="copy-box">
        <img class="close" src="../assets/img/close.png" alt="" @click="showCopy=false"/>
        <div class="title">报名成功！</div>
        <div class="text">作品上传地址稍后发送至您的邮箱，
          <br/>
          您也可以用PC浏览器自行登录以
          <br />
          下地址进行上传作品
        </div>
        <div class="text">官网 红色文创大赛<br />http://fjwc.fzwtjt.com</div>
        <div ref="copy" class="copy" data-clipboard-action="copy" 
        data-clipboard-text="http://fjwc.fzwtjt.com">复制网址</div>
      </div>
    </div>
  </div>
</template>

<script>
import { signUp } from "../api/api";
import clipboard from 'clipboard';
var copyBtn;
export default {
  data() {
    return {
      showCopy: false,
      name: "",
      phone: "",
      email: "",
      errorName: "",
      errorPhone: "",
      errorEmail: "",
      teacher: "",
      company: "",

      selectList: [
        {
          checked: false,
          name: "红色纹样",
        },
        {
          checked: false,
          name: "红色办公",
        },
        {
          checked: false,
          name: "红色工艺",
        },

      ],
    };
  },
  mounted(){
    let that = this;
      copyBtn = new clipboard(this.$refs.copy);
      //成功回调
      copyBtn.on('success', function() {
        that.$message.success("复制成功")
        that.showCopy = false;
      });
      copyBtn.on('error', function() {
        that.$message.error("复制失败")
      });
  },
  methods: {
    check(index) {
      this.type = index + 1;

      let item = this.selectList[index];
      item.checked = !item.checked;
      this.$set(this.selectList, index, item);
    },
    submit() {
      this.errorName = "";
      this.errorPhone = "";
      this.errorEmail = "";
      if (!this.name) {
        this.errorName = "请输入姓名";
        return;
      }
      if (!this.phone) {
        this.errorPhone = "请输入手机号";
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.errorPhone = "手机号格式错误";
        return;
      }

      if (!this.email) {
        this.errorEmail = "请输入邮箱";
        return;
      }
      const reg = /^[\w'.%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,4}$/;
      if (!reg.test(this.email)) {
        //正则验证不通过，格式不对
        this.errorEmail = "邮箱格式错误";
        return;
      }

      let type = [];
      for (const item of this.selectList) {
        if (item.checked) {
          type.push(item.name);
        }
      }
      if (type.length <= 0) {
        this.$message("请选择参赛作品类别");
        return;
      }

      signUp({
        name: this.name,
        mobile: this.phone,
        email: this.email,
        company: this.company,
        teacher: this.teacher,
        type: type,
      }).then((res) => {
        console.log(res)
        // this.showSuccess = true;
        if(res.data.msg == "重复报名"){
          this.showAddress = true;
        }else{
          this.showCopy = true;
        }

      });
    },
  },
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "NotoSansHans";
  src: url("../assets/font/NotoSansHans-Regular.ttf");
}


.baoming-page{
  *{
    margin: 0;
    padding: 0;
  }
  color: #E5A36F;
  font-size: 15px;
  background-color: #7c0306;
  width: 100%;
  height: 100vh;
  position: relative;
  margin: 0;
  padding: 0;
  overflow: auto;
  font-family: "NotoSansHans", "PingFang SC", "Microsoft Yahei", sans-serif;
  .container{
    position: relative;
    padding: 0 20px 20px;
    &::before{
      content: '';
      bottom: 0;
      left: 0;
      width: 80%;
      height: 220px;
      background: url("https://culture-fzwc.oss-cn-beijing.aliyuncs.com/bg_page_1.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
    }
    &::after{
      content: '';
      bottom: 0;
      right: 0;
      width: 60%;
      height: 180px;
      background: url("https://culture-fzwc.oss-cn-beijing.aliyuncs.com/bg_page_3.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
    }
    input{
      color: #E5A36F;
    }
  }
  a{
    color: #E5A36F;

  }
  img{width: 100%}
  .title-wrap{
    text-align: center;
    margin-top: 55px;
    .title-box{
      position: relative;
      display: inline-block;
      color: #FCEAA7;
      font-size: 26px;
      background: url("../assets/img/bg_title.png") no-repeat;
      background-size: 100% 100%;
      padding: 0 55px;
      letter-spacing: 2px;
      font-weight: 500;
      margin-bottom: 20px;
      &::before,&::after{
        content: '';
        display: block;
        position: absolute;
      }
      &::before{
        left: 50%;
        margin-left: -41px;
        background: url("../assets/img/img_title_top.png") no-repeat;
        background-size: 100% 100%;
        height: 41px;
        width: 82px;
        top: -32px;
      }
      &::after{
        background: url("../assets/img/bg-title-box.png") no-repeat;
        background-size: 100% 100%;
        height: 96px;
        width: 188px;
        bottom: -1px;
        right: -20px;
      }
    }
  }
  .sec-box{
    position: relative;
    //background-color: #9B0000;
    padding: 0 8px 16px;
    background-image: linear-gradient(
        180deg
        , rgba(173,0,0,0.15), rgba(173,0,0,0.6), rgba(173,0,0,0.15));
    .form-box{position: relative}
    .form-item{
      position: relative;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #A80300;
      margin: 0 5px 20px 10px;
      font-size: 16px;
      .label{
        width: 100px;
        line-height: 32px;
      }
      .input {
        height: 32px;
        text-align: right;
        font-size: 16px;
      }
      .input::-webkit-input-placeholder {
        color: #E5A36F;
        opacity: 0.42;
      }
      .input:-moz-placeholder {
        /* Firefox 18- */
        color: #E5A36F;
        opacity: 0.42;
      }
      .input::-moz-placeholder {
        /* Firefox 19+ */
        color: #E5A36F;
        opacity: 0.42;
      }
      .input:-ms-input-placeholder {
        color: #E5A36F;
        opacity: 0.42;
      }
      .error {
        position: absolute;
        top: 35px;
        font-size: 14px;
        opacity: 0.65;
      }
    }
    .select-title{
      font-size: 17px;
      font-weight: 500;
      padding-left: 10px;
      margin-bottom: 12px;
    }
    .select-list {
      .item {
        font-size: 14px;
        line-height: 28px;
        padding:  0 12px;
        img{width: 18px;height:18px;margin-right: 4px;position: relative;top: 3px}
        .name{
          display: inline-block;
        }
      }
    }
    .form-btn{
      position: relative;
      margin: 30px 10px;
      height: 56px;
      line-height: 56px;
      background: url("../assets/img/bg-btn1.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      font-size: 22px;
      color: #A40607;
      letter-spacing: 2px;
    }
    .tip-text {
      font-size: 15px;
      line-height: 30px;
      p{
        text-indent: 0.9em;
      }
      .tel {
        width: 12px;
        height: 12px;
        margin-right: 3px;
      }
    }
    .qrcode{
      display: block;
      width: 240px;
      margin: 20px auto;
    }
  }
  .bg-img-wrap:before{
    content: '';
    display: block;
    height: 300px;
    width: 60%;
    background: url('../assets/img/bg_page_3.png');
    background-size: 100% 100%;
    position: absolute;
    left: -20px;
    bottom: -30px;
  }
}

.success {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.success .content {
  width: 12rem;
  height: 12rem;
  margin: 40% auto 0 auto;
  background: url("../assets/img/success_bg.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}

.success .content img {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  margin: 1.5rem auto 1rem auto;
}
.success .content .text {
  margin: 0 auto 0 auto;
  color: #ca1c1d;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
}

.success .content .know {
  display: block;
  width: 10rem;
  height: 2rem;
  background: #033a67;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto 0 auto;
}

.success .copy-box {
  width: 14rem;
  height: 16rem;
  margin: 40% auto 0 auto;
  overflow: hidden;
  position: relative;
  background: white;
}

.success .copy-box .close {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  right: 0;
  top: 0;
  padding: 0.5rem;
}
.success .copy-box .title {
  color: #333333;
  font-size: 1rem;
  margin-top: 2.5rem;
  margin-left: 2rem;
  font-weight: 900;
}
.success .copy-box .text {
  color: #033a67;
  font-size: 0.75rem;
  margin-top: 0.5rem;
   margin-left: 2rem;
}

.success .copy-box .copy {
  display: block;
  width: 10rem;
  height: 2rem;
  background: #033a67;
  color: white;
  font-size: 0.85rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem auto 0 auto;
}
.success .copy-box {
  width: 14rem;
  height: 16rem;
  margin: 40% auto 0 auto;
  overflow: hidden;
  position: relative;
  background: white;
}

.success .copy-box .close {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  right: 0;
  top: 0;
  padding: 0.5rem;
}
.success .copy-box .title {
  color: #333333;
  font-size: 0.8rem;
  margin-top: 2.5rem;
  margin-left: 2rem;
}
.success .copy-box .text {
  color: #033a67;
  font-size: 0.75rem;
  margin-top: 0.5rem;
  margin-left: 2rem;
}

.success .copy-box .copy {
  display: block;
  width: 10rem;
  height: 2rem;
  background: #033a67;
  color: white;
  font-size: 0.85rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem auto 0 auto;
}
</style>
