// ajax请求
import axios from "axios"
import store from '@/store'
import router from '@/router'
import {Loading, Message} from 'element-ui';
import qs from 'qs'

var loadingInstance;
// axios实例
axios.defaults.timeout = 30000;

axios.defaults.baseURL = 'https://admin.fjwc.aiecofish.net/';
// axios.defaults.baseURL = 'http://fzwc-admin.local';


// request拦截器
axios.interceptors.request.use(
    config => {
      if (config.headers['Content-Type'] != "multipart/form-data") {
        if (store.getters.jwt) {
          config.headers['jwt'] = store.getters.jwt;
        }
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        config.data = qs.stringify(config.data);
      }
      loadingInstance = Loading.service({text: '加载中...'});
      return config
    },
    error => {
      loadingInstance.close();
      return Promise.reject(error)
    }
)

// response 拦截器
axios.interceptors.response.use(
    response => {
      loadingInstance.close();
      return response;
    },
    error => {
      loadingInstance.close();
      return Promise.reject(error)
    }
)

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    }).then(response => {
      if (response.data.code === 0) {
        resolve(response.data);
      } else if (response.data.code == 1001) {
        router.push("/login");
        // reject(response.data.msg);
      } else {
        let message;
        if (response.data.msg) {
          message = response.data.msg
        } else if (response.data) {
          message = JSON.stringify(response.data);
        } else {
          message = JSON.stringify(response);
        }
        if (message) {
          Message({
            showClose: true,
            message: message,
            type: 'error'
          })
        }

        reject(response.data)
      }
    }).catch(err => {
      if (err) {
        Message({
          showClose: true,
          message: JSON.stringify(err),
          type: 'error'
        })
      }
      reject(err)
    })
  })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {

  return new Promise((resolve, reject) => {
    axios.post(url, data)
        .then(response => {
          if (response.data.code === 0) {
            resolve(response.data);
          } else if (response.data.code == 1001) {
            router.push("/login");
            // return reject(response.data.msg);
          } else {

            let message;
            if (response.data.msg) {
              message = response.data.msg
            } else if (response.data) {
              message = JSON.stringify(response.data);
            } else {
              message = JSON.stringify(response);
            }
            if (message) {
              Message({
                showClose: true,
                message: message,
                type: 'error'
              })
            }
            reject(response.data)
          }
        }).catch(err => {
      if (err) {
        Message({
          showClose: true,
          message: JSON.stringify(err),
          type: 'error'
        })
      }
    })
  })
}
