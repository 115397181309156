<template>
  <wrapper-page type="mine" :upload="false">
    <div class="login">
      <div class="img-box">
        <img src="https://culture-fzwc.oss-cn-beijing.aliyuncs.com/title.png" />
      </div>

      <div class="content">
        <div class="title">欢迎登陆</div>

        <div class="input-box">
          <div class="input-con">
            <img class="img" src="../assets/img/account.png" alt="" />
            <el-input
              class="input"
              v-model="mobile"
              type="text"
              maxlength="11"
              placeholder="请输入手机号"
            ></el-input>
          </div>
        </div>
        <div class="input-box">
          <div class="input-con">
            <img class="img" src="../assets/img/email.png" alt="" />

            <el-input
              class="input"
              v-model="code"
              maxlength="6"
              placeholder="请输入验证码"
            ></el-input>
          </div>

          <div class="code" @click="getCode">{{ codeText }}</div>
        </div>

        <div class="btn" @click="login">登 录</div>
      </div>
    </div>
  </wrapper-page>
</template>

<script>
import { smsCode, login } from "../api/api";
import store from "@/store";
import WrapperPage from "../components/wrapperPage.vue";
var timer;

export default {
  components: { WrapperPage },

  data() {
    return {
      mobile: "",
      code: "",
      time: 60,
      codeText: "获取短信验证",
      isTime: false,
    };
  },
  mounted(){
      if(store.getters.jwt !== undefined && store.getters.jwt !== '') {
        let url = store.getters.routerUrl;
        this.$router.replace(url);
      }
  },

  methods: {
    getCode() {



      if (!this.mobile) {
        this.$message("请输入手机号");
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
        this.$message("手机号格式错误");
        return;
      }
      if (this.isTime) {
        return;
      }

      if (timer) {
        clearInterval(timer);
      }
      this.time = 60;
      this.isTime = true;
      timer = setInterval(() => {
        if (this.time <= 0) {
          clearInterval(timer);
          this.isTime = false;
          this.time = 60;
          this.codeText = "获取短信验证";
        } else {
          this.time--;
          this.codeText = this.time + " S";
        }
      }, 1000);

      smsCode({ mobile: this.mobile })
        .then(() => {
          this.$message.success("发送成功");
          // clearInterval(timer);
          // this.isTime = false;
          // this.time = 60;
          // this.codeText = "获取短信验证";
        })
        .catch(() => {
          clearInterval(timer);
          this.isTime = false;
          this.time = 60;
          this.codeText = "获取短信验证";
        });
    },
    login() {
      if (!this.mobile) {
        this.$message("请输入手机号");
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
        this.$message("手机号格式错误");
        return;
      }
      if (!this.code) {
        this.$message("请输入验证码");
        return;
      }
      login({ mobile: this.mobile, code: this.code }).then((res) => {
        this.$message.success("登录成功");
        store.commit("saveJwt", res.data.jwt);
        store.commit("saveUserInfo", res.data);

        // let url = store.getters.routerUrl;
        this.$router.replace('/upload');
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../assets/style/style.less";

.login {
  width: 100%;
  min-height: calc(100vh - 48px);
  //min-width: 1152px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  background-image: url("https://culture-fzwc.oss-cn-beijing.aliyuncs.com/login_bg-min.jpg");
  background-repeat:no-repeat;
  background-size:100% 100%;
  -moz-background-size:100% 100%;


  .img-box {
    flex: 1;

    img {
      margin: 2rem 0 2rem 2.5rem;
      width: 100%;
    }
  }
  .content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: auto;

    .title {
      color: white;
      // margin-top: 15rem;
      font-size: 1.8rem;
      padding: 0.1rem;
      margin-bottom: 3rem;
      border-bottom: 3px solid #FD953A;
    }

    .input-box {
      color: white;
      width: 25rem;
      height: 3.5rem;
      display: flex;
      justify-content: space-between;

      .input-con::v-deep {
        color: white;
        flex: 1;
        height: 3.5rem;
        border-bottom: 1px solid white;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          width: 1.3rem;
          margin: 0 0.5rem;
        }
        input {
          line-height: 3.5rem;
          height: 3.5rem;
          flex: 1;
          font-size: 1.2rem;
          //background-color: none;
          background: none;
          border: none;
          color: white !important;
        }
        input::-webkit-input-placeholder {
          color: #ddd;
        }
        input:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #ddd;
        }
        input::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #ddd;
        }
        input:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #ddd;
        }
      }

      .code {
        width: 8rem;
        height: 2.1rem;
        display: flex;
        margin-top: 1.3rem;
        justify-content: center;
        margin-left: 0.8rem;
        align-items: center;
        background: #FFE8D3;
        border: 1px solid #FD953A;
        font-size: 1rem;
        color: #FD953A;
        cursor: pointer;
      }
    }

    .btn {
      margin-top: 3.8rem;
      width: 13rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #FD953A;
      font-size: 1.5rem;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
